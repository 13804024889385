// import themeMedia from './theme-media'
// themeMedia()

Promise.all(
    [
        import('./navOffcanvas'),
        import('./serviziSlider'),
        import('./gallerySlider'),
        import('./contactForm'),
        import('./scrollTopNav'),
        import('./scrollAnimation')
    ]
).then(
    (
        [
            navOffcanvas,
            serviziSlider,
            gallerySlider,
            contactForm,
            scrollTopNav,
            scrollAnimation,
        ]
    ) => {
        navOffcanvas.default()
        serviziSlider.default()
        gallerySlider.default()
        contactForm.default()
        scrollTopNav.default()
        scrollAnimation.default()
    }
)